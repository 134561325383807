//* 收件人
// 新增地址 AddHomeAddress.vue
// 購物車結帳 Payment.vue
export let mockReceiverData = {
  name: '',
  mobile: '',
  county: '',
  district: '',
  detail: '',
  store_id: '',
  store_name: ''
}

//* 商品列表
export const mockProductListData = [
  {
    id: '117216',
    name: '【團】【Viewsonic 優派】27型 IPS 電競螢幕 顯示器 XG2705',
    desc:
      '<p>144Hz刷新率<br />\n可傾斜、水平轉動、垂直旋轉、高度調整<br />\n一年無亮點保固<br />\n保內送修，原廠免費提供備機</p>\n<p><img src="https://www.cocorolife.tw/Images//productdesc/20210309085027.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085031.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085036.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085038.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085052.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085056.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085100.jpg" /></p>\n',
    image: 'http://210.200.141.54/product/thumb/117216/20210518104020.jpg',
    spans: [
      {
        id: 1,
        name: '熱銷商品'
      }
    ],
    packs: [
      {
        id: 1,
        name: '單入組',
        size: 1,
        price: 100
      },
      {
        id: 2,
        name: '三入組',
        size: 3,
        price: 300
      },
      {
        id: 1,
        name: '五入組',
        size: 5,
        price: 500
      }
    ],
    mockStatus: 1, //假的 1退貨 2已結單 3待退款
    mockTotal: 10000,
    mockAverage: 3333
  },
  {
    id: '117219',
    name: '【團】【Viewsonic 優派】27型 IPS 電競螢幕 顯示器 XG2705',
    desc:
      '<p>144Hz刷新率<br />\n可傾斜、水平轉動、垂直旋轉、高度調整<br />\n一年無亮點保固<br />\n保內送修，原廠免費提供備機</p>\n<p><img src="https://www.cocorolife.tw/Images//productdesc/20210309085027.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085031.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085036.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085038.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085052.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085056.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085100.jpg" /></p>\n',
    image: 'http://210.200.141.54/product/thumb/117216/20210518104020.jpg',
    spans: [
      {
        id: 1,
        name: '熱銷商品'
      }
    ],
    packs: [
      {
        id: 1,
        name: '單入組',
        size: 1,
        price: 100
      },
      {
        id: 2,
        name: '三入組',
        size: 3,
        price: 300
      },
      {
        id: 1,
        name: '五入組',
        size: 5,
        price: 500
      }
    ],
    mockStatus: 2, //假的 1退貨 2已結單 3待退款
    mockTotal: 10000,
    mockAverage: 3333
  },
  {
    id: '117220',
    name: '【團】【Viewsonic 優派】27型 IPS 電競螢幕 顯示器 XG2705',
    desc:
      '<p>144Hz刷新率<br />\n可傾斜、水平轉動、垂直旋轉、高度調整<br />\n一年無亮點保固<br />\n保內送修，原廠免費提供備機</p>\n<p><img src="https://www.cocorolife.tw/Images//productdesc/20210309085027.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085031.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085036.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085038.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085052.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085056.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085100.jpg" /></p>\n',
    image: 'http://210.200.141.54/product/thumb/117216/20210518104020.jpg',
    spans: [
      {
        id: 1,
        name: '熱銷商品'
      }
    ],
    packs: [
      {
        id: 1,
        name: '單入組',
        size: 1,
        price: 100
      },
      {
        id: 2,
        name: '三入組',
        size: 3,
        price: 300
      },
      {
        id: 1,
        name: '五入組',
        size: 5,
        price: 500
      }
    ],
    mockStatus: 3, //假的 1退貨 2已結單 3待退款 4已退款
    mockTotal: 10000,
    mockAverage: 3333,
    mockRefound: 5000
  },

  {
    id: '117299',
    name: '【團】【Viewsonic 優派】27型 IPS 電競螢幕 顯示器 XG2705',
    desc:
      '<p>144Hz刷新率<br />\n可傾斜、水平轉動、垂直旋轉、高度調整<br />\n一年無亮點保固<br />\n保內送修，原廠免費提供備機</p>\n<p><img src="https://www.cocorolife.tw/Images//productdesc/20210309085027.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085031.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085036.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085038.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085052.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085056.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085100.jpg" /></p>\n',
    image: 'http://210.200.141.54/product/thumb/117216/20210518104020.jpg',
    spans: [
      {
        id: 1,
        name: '熱銷商品'
      }
    ],
    packs: [
      {
        id: 1,
        name: '單入組',
        size: 1,
        price: 100
      },
      {
        id: 2,
        name: '三入組',
        size: 3,
        price: 300
      },
      {
        id: 1,
        name: '五入組',
        size: 5,
        price: 500
      }
    ],
    mockStatus: 4, //假的 1退貨 2已結單 3待退款 4已退款
    mockTotal: 10000,
    mockAverage: 3333,
    mockRefound: 5000,
    mockRefoundFinished: 5000
  }
]

//* 商品詳細
// 購物車結帳 Payment.vue
export const mockProductData = {
  id: '117216',
  name: '【團】【Viewsonic 優派】27型 IPS 電競螢幕 顯示器 XG2705',
  desc:
    '<p>144Hz刷新率<br />\n可傾斜、水平轉動、垂直旋轉、高度調整<br />\n一年無亮點保固<br />\n保內送修，原廠免費提供備機</p>\n<p><img src="https://www.cocorolife.tw/Images//productdesc/20210309085027.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085031.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085036.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085038.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085052.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085056.jpg" /><img src="https://www.cocorolife.tw/Images//productdesc/20210309085100.jpg" /></p>\n',
  image: 'http://210.200.141.54/product/thumb/117216/20210518104020.jpg',
  spans: [
    {
      id: 1,
      name: '熱銷商品'
    }
  ],
  packs: [
    {
      id: 1,
      name: '單入組',
      size: 1,
      price: 100
    },
    {
      id: 2,
      name: '三入組',
      size: 3,
      price: 300
    },
    {
      id: 1,
      name: '五入組',
      size: 5,
      price: 500
    }
  ]
}

//* 模擬幾入組的資料
export let mockDataSpacks = [
  {
    id: 1,
    name: '規格A',
    bought: 10 //買了幾個
  },
  {
    id: 2,
    name: '規格B',
    bought: 5
  },
  {
    id: 3,
    name: '規格C',
    bought: 0
  }
]

// 購物車結帳 Products/ProductDetail.vue
//* 模擬商品資料
export const cartDataList = [{
  product: {
    id: '555555',
    name: '【團】模擬資料商品',
    image: 'http://210.200.141.54/product/thumb/117216/20210518104020.jpg'
  },
  price: {
    total: 100,
    average: 150
  },
  specs: [
    { id: 1, name: '紅色' },
    { id: 2, name: '黃色' },
    { id: 3, name: '藍色' }
  ],
  packs: [
    {
      id: 1,
      name: '單入組',
      size: 1,
      price: {
        grouped: 100,
        suggested: 150
      },
      stock: 0,
      status: 0, //已刪除!
      spec: 0, //組合購買規格
      quantity: 0 //組合購買數量
    },
    {
      id: 2,
      name: '二入組',
      size: 2,
      price: {
        grouped: 200,
        suggested: 250
      },
      stock: 0,
      status: 1,
      spec: 0, //組合購買規格
      quantity: 0 //組合購買數量
    },
    {
      id: 3,
      name: '三入組',
      size: 3,
      price: {
        grouped: 300,
        suggested: 450
      },
      stock: 0,
      status: 1,
      spec: 0, //組合購買規格
      quantity: 0 //組合購買數量
    }
  ]
}]

 export const ordersListMockData = [
  {
    "id": 65377,
    "no": "OD21070368073429",
    "satatus": 0,
    "amount": 7900,
    "created_at": "2021-07-03 14:08:21",
    "product": [
      {
        "id": 129315,
        "name": "PANASONIC國際牌藍牙/USB組合音響 SC-PM250 ",
        "image": "/product/thumb/129315/20210630020431.jpg"
      },
      {
        "id": 129315,
        "name": "PANASONIC國際牌藍牙/USB組合音響 SC-PM250 ",
        "image": "/product/thumb/129315/20210630020431.jpg"
      }
    ],
    "delivery": [
      {
        "type": 0,
        "method": "宅配到府",
        "provider": "",
        "no": "",
        "name": null,
        "mobile": null,
        "address": null,
        "status": 0
      },
      {
        "type": 0,
        "method": "宅配到府",
        "provider": "",
        "no": "",
        "name": null,
        "mobile": null,
        "address": null,
        "status": 0
      }
    ]
  },
  {
    "id": 65375,
    "no": "OD21070328575416",
    "satatus": 0,
    "amount": 7900,
    "created_at": "2021-07-03 11:09:01",
    "product": [
      {
        "id": 129315,
        "name": "PANASONIC國際牌藍牙/USB組合音響 SC-PM250 ",
        "image": "/product/thumb/129315/20210630020431.jpg"
      },
      {
        "id": 129315,
        "name": "PANASONIC國際牌藍牙/USB組合音響 SC-PM250 ",
        "image": "/product/thumb/129315/20210630020431.jpg"
      }
    ],
    "delivery": [
      {
        "type": 0,
        "method": "宅配到府",
        "provider": "",
        "no": "",
        "name": null,
        "mobile": null,
        "address": null,
        "status": 0
      },
      {
        "type": 0,
        "method": "宅配到府",
        "provider": "",
        "no": "",
        "name": null,
        "mobile": null,
        "address": null,
        "status": 0
      }
    ]
  },
  {
    "id": 65372,
    "no": "OD21070318717675",
    "satatus": 0,
    "amount": 3950,
    "created_at": "2021-07-03 10:24:05",
    "product": [
      {
        "id": 129315,
        "name": "PANASONIC國際牌藍牙/USB組合音響 SC-PM250 ",
        "image": "/product/thumb/129315/20210630020431.jpg"
      }
    ],
    "delivery": [
      {
        "type": 0,
        "method": "超商取貨",
        "provider": "",
        "no": "",
        "name": null,
        "mobile": null,
        "address": null,
        "status": 0
      }
    ]
  },
  {
    "id": 65369,
    "no": "OD21070375304521",
    "satatus": 0,
    "amount": 3950,
    "created_at": "2021-07-03 08:01:04",
    "product": [
      {
        "id": 129315,
        "name": "PANASONIC國際牌藍牙/USB組合音響 SC-PM250 ",
        "image": "/product/thumb/129315/20210630020431.jpg"
      }
    ],
    "delivery": [
      {
        "type": 0,
        "method": "超商取貨",
        "provider": "",
        "no": "",
        "name": null,
        "mobile": null,
        "address": null,
        "status": 0
      }
    ]
  },
  {
    "id": 65366,
    "no": "OD21070346154829",
    "satatus": 0,
    "amount": 3950,
    "created_at": "2021-07-03 08:00:30",
    "product": [
      {
        "id": 129315,
        "name": "PANASONIC國際牌藍牙/USB組合音響 SC-PM250 ",
        "image": "/product/thumb/129315/20210630020431.jpg"
      }
    ],
    "delivery": [
      {
        "type": 0,
        "method": "超商取貨",
        "provider": "",
        "no": "",
        "name": null,
        "mobile": null,
        "address": null,
        "status": 0
      }
    ]
  },
  {
    "id": 65363,
    "no": "OD21070342086209",
    "satatus": 0,
    "amount": 3950,
    "created_at": "2021-07-03 08:00:03",
    "product": [
      {
        "id": 129315,
        "name": "PANASONIC國際牌藍牙/USB組合音響 SC-PM250 ",
        "image": "/product/thumb/129315/20210630020431.jpg"
      }
    ],
    "delivery": [
      {
        "type": 0,
        "method": "超商取貨",
        "provider": "",
        "no": "",
        "name": null,
        "mobile": null,
        "address": null,
        "status": 0
      }
    ]
  },
  {
    "id": 65360,
    "no": "OD21070353826566",
    "satatus": 0,
    "amount": 3950,
    "created_at": "2021-07-03 07:57:15",
    "product": [
      {
        "id": 129315,
        "name": "PANASONIC國際牌藍牙/USB組合音響 SC-PM250 ",
        "image": "/product/thumb/129315/20210630020431.jpg"
      }
    ],
    "delivery": [
      {
        "type": 0,
        "method": "宅配到府",
        "provider": "",
        "no": "",
        "name": null,
        "mobile": null,
        "address": null,
        "status": 0
      }
    ]
  },
  {
    "id": 65359,
    "no": "OD21070365200266",
    "satatus": 0,
    "amount": 3950,
    "created_at": "2021-07-03 07:53:08",
    "product": [
      {
        "id": 129315,
        "name": "PANASONIC國際牌藍牙/USB組合音響 SC-PM250 ",
        "image": "/product/thumb/129315/20210630020431.jpg"
      }
    ],
    "delivery": [
      {
        "type": 0,
        "method": "宅配到府",
        "provider": "",
        "no": "",
        "name": null,
        "mobile": null,
        "address": null,
        "status": 0
      }
    ]
  },
  {
    "id": 65296,
    "no": "OD21070124006700",
    "satatus": 0,
    "amount": 3950,
    "created_at": "2021-07-01 12:00:00",
    "product": [
      {
        "id": 129315,
        "name": "PANASONIC國際牌藍牙/USB組合音響 SC-PM250 ",
        "image": "/product/thumb/129315/20210630020431.jpg"
      }
    ],
    "delivery": [
      {
        "type": 0,
        "method": "宅配到府",
        "provider": "",
        "no": "",
        "name": null,
        "mobile": null,
        "address": null,
        "status": 0
      }
    ]
  },
  {
    "id": 65293,
    "no": "OD21070162283565",
    "satatus": 0,
    "amount": 3950,
    "created_at": "2021-07-01 11:59:36",
    "product": [
      {
        "id": 129315,
        "name": "PANASONIC國際牌藍牙/USB組合音響 SC-PM250 ",
        "image": "/product/thumb/129315/20210630020431.jpg"
      }
    ],
    "delivery": [
      {
        "type": 0,
        "method": "宅配到府",
        "provider": "",
        "no": "",
        "name": null,
        "mobile": null,
        "address": null,
        "status": 0
      }
    ]
  },
  {
    "id": 65290,
    "no": "OD21070127101822",
    "satatus": 0,
    "amount": 3950,
    "created_at": "2021-07-01 11:58:40",
    "product": [
      {
        "id": 129315,
        "name": "PANASONIC國際牌藍牙/USB組合音響 SC-PM250 ",
        "image": "/product/thumb/129315/20210630020431.jpg"
      }
    ],
    "delivery": [
      {
        "type": 0,
        "method": "宅配到府",
        "provider": "",
        "no": "",
        "name": null,
        "mobile": null,
        "address": null,
        "status": 0
      }
    ]
  }
]

// QA模擬資料
export const qaDataList = [
  {
    name: '註冊',
    value: [
      {
        question: '一定要有社群才能加入嗎?',
        answer: '是的，揪美美是專為團購主提供批發好價格的選物平台，所以會員門檻需要至少一個團購社群（FB/LINE任一即可），社群沒有人數限制，歡迎新手團爸團媽加入喔！'
      },
      {
        question: '審核需要多少時間?',
        answer: '註冊後約7個工作天內會完成審核。'
      },
      {
        question: '我沒有邀請碼怎麼辦?',
        answer: '邀請碼並非必填欄位，即便留空也能送出註冊。'
      },
      {
        question: '我的手機號碼已被使用?',
        answer: '如發生此狀況，請洽揪美美客服專人查詢。LINE客服帳號：<a href="https://lin.ee/Pg3oP7b">https://lin.ee/Pg3oP7b</a>'
      },
    ]
  },
  {
    name: '購買寄送',
    value: [
      {
        question: '有購買數量的限制嗎?',
        answer: '揪美美平台沒有購買數量的限制，您可以依照賣場內的組數進行購買。'
      },
      {
        question: '運費怎麼計算?',
        answer: '揪美美全平台販售的商品皆免運費。'
      },
      {
        question: '商品配送限制為何?',
        answer: '商品運送方式為宅配，配送地區限臺灣本島，目前暫不包括澎湖縣（限馬公、白沙、西嶼、湖西）、金門縣（限金沙、金城、金湖、金寧）、小琉球、連江縣、綠島、蘭嶼及南海諸島等。'
      }
    ]
  },
  {
    name: '訂單進度',
    value: [
      {
        question: '如何追蹤訂單進度?',
        answer: '請至『購物紀錄中』中， 確認您的訂單。若配送狀態顯示<span class="text-pink-salmon font-bold">『已付款』</span>即代表訂購已成功。點擊此三角箭頭向下展開即可查看訂單進度。'
      },
      {
        question: '如何查看收件人資訊?',
        answer: '請至『購物紀錄中』中， 確認您的訂單。點擊<span class="text-pink-salmon font-bold">『顯示收件人資訊』</span>即可查看收件人姓名、電話及地址等資訊。'
      },
      {
        question: '如何取消訂單?',
        answer: '請至『購物紀錄中』中 ， 選擇您要取消的訂單，點擊『取消』，即可取消您的該筆訂單。<span class="text-pink-salmon">***若是配送狀態已進入『理貨中』或『出貨中』，您將無法自行取消訂單，請聯繫客服人員。</span>'
      }
    ]
  },
  {
    name: '發票',
    value: [
      {
        question: '為何沒有收到紙本發票?',
        answer: '根據財政部令 台財資字第 1070001765 號 「電子發票實施作業要點」，自 103 年 1 月 1 日起，於本網站消費開立之電子發票，無實體紙本發票故不會寄送。本網站會於期限內將開立之電子發票，上傳至財政 部電子發票整合服務平台留存。您可利用會員專區>客服中心/常見問題>電子發票歸戶功能，進行會員載具歸戶作 業。經歸戶後的中獎發票將由「財政部電子發票整合平台」進行領獎相關通知及作業；未歸戶的中獎發票，將由本網站以電子郵件、推播及簡訊通知您領獎相關通知及作業。(電子發票及會員載具歸戶說明， 詳見財政部電子發票整合平台 )'
      },
      {
        question: '如需要紙本發票，該如何列印?',
        answer: '系統將寄送電子發票至您的 E-mail 信箱，您可自行截圖列印。<span class="text-pink-salmon font-bold">若您的電子發票有中獎，可於發票期別有效期間至7-ELEVEN ibon機台列印消費發票，等同正式發票可兌獎。</span>（如逾期將不得進行列印）※操作步驟如下：於ibon機台點選「生活服務」>「電子發票」>「綠界科技」>「消費發票列印」（(未兌獎)）輸入發票號碼 & 隨機碼，確認後列印消費發票，或參考ibon發票列印服務介紹'
      },
      {
        question: '如何領取中獎發票及兌獎?',
        answer: '每逢單月 25 日開獎後，將由「財政部電子發票整合平台」統一進行對獎作業，於單月 28 日收到「財政部電子發票整合平台」提供中獎清冊後， 於訂單查詢頁「發票」顯示中獎資訊，通知方式將依消費者使用發票載具類型及載具歸戶狀態，處理方式如下：<ul class="list-disc list-inside"><li>發票載具已歸戶：使用「手機條碼載具 」或「自然人憑證載具」，中獎發票將由「財政部電子發票整合平台」進行領獎相關通知及作業。</li><li>發票載具未歸戶：由本網站以電子郵件通知您領獎相關通知及作業。請您收到 E-mail 後，至 7-ELEVEN ibon 機台列印中獎電子發票</li></ul>'
      }
    ]
  },
  {
    name: '退換貨/退款說明',
    value: [
      {
        question: '退換貨說明',
        answer: '感謝您的購物，揪美美依據台灣消保法的規定，您可以依照收到商品（(以物流單據上的簽收日期為準)）次日起之“7天鑑賞期”內申請退貨，退貨商品需符合「退貨條件」。一旦完成官網線上退貨申請，我們配合的物流廠商便會安排前往取回。目前我們暫不提供換貨服務，敬請見諒。'
      },
      {
        question: '退貨條件',
        answer: '<ul class="list-disc list-inside"><li>退貨商品仍在7天鑑賞期內：依照收到商品（以物流單據上的簽收日期為準）次日起的7天之內。</li><li>退貨商品需保持完好、未經拆封、使用，且不影響二次銷售。</li><li>貼身用品（(如口罩/面罩/運動內衣/襪子…等)）因衛生問題恕不退貨</li><li>生鮮食品（若非特殊原因，如品質不良），恕不接受退貨</li></ul>'
      },
      {
        question: '何時可收到退款',
        answer: '取消訂單或是退貨之退款，若符合退貨條件，且退款帳戶資訊正確無誤，我們將會為您進行退款作業。<ul class="list-disc list-inside"><li><span class="text-pink-salmon font-bold">透過信用卡付款者</span>：按下退貨後，請您填寫『退貨原因』，並按下『確認』按鍵。若經審核符合退貨條件，物流人員將於 3-7 個工作天內前往取件。退貨之商品經由廠商驗退，揪美美核算後，將直接刷退至您當初使用來付款的信用卡帳戶中，且<span class="text-pink-salmon">將於您的下一期信用卡帳單中顯示（ (平均工作時間約 7-10 個工作天，視發卡銀行而定)。</span></li><li><span class="text-pink-salmon font-bold">透過 ATM 付款者</span>：按下單品項退貨後，請您填寫『退貨原因』、『退款資訊』包含戶名、銀行帳號，並提供銀行存摺影本上傳。全部填妥後按下『確認』按鍵。若經審核符合退貨條件，物流人員將於 3-7 個工作天內前往取件。退貨之商品經由廠商驗退，揪美美核算退款，約需三週的對帳流程。退款會先扣除轉帳手續費 15元，並於<span class="text-pink-salmon">三週後的每月10號或25號退款至您的帳戶。***原ATM 付款人與退款人需相同。若原ATM 付款人與退款人不同時，將由客服提供您切結書填寫，以維護原付款人之權益。</span></li></ul>'
      }
    ]
  },
]