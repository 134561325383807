<template>
  <article class="pt-12 md:pt-0 md:max-w-1200 md:mx-auto">

      <article class="bg-white pt-1 mt-3">
        <section class="container-wrapper">
          <header class="text-2xl font-bold text-brown mb-4">
            Q&A
          </header>
          <article >
            <div class="py-4" v-for="(item, index) in qaDataList" :key="`qaMain_${index}`">
              <div>
                <h2 class="text-xl text-pink-salmon font-semibold pl-2 border-l-4 border-pink-salmon">{{ item.name }}</h2>
              </div>
              <div class="pl-4" v-for="(subItem, subIndex) in item.value" :key="`qaSub_${subIndex}`">
                <h4 class="text-pink-salmon-active text-lg">{{ subItem.question }}</h4>
                <div class="text-lg" v-html="subItem.answer"></div>
              </div>
            </div>
          </article>
        </section>
      </article>

  </article>
</template>

<script>
import { qaDataList } from '@/data/mockData.js'

export default {
  name: '',
  components: {},
  data () {
    return {
      qaDataList
    }
  },
  // 監聽屬性 類似於data概念
  computed: {},
  // 監控data中的資料變化
  watch: {},
  // 生命週期 - 創建完成（可以訪問當前this實例）
  created () {

  },
  // 生命週期 - 掛載完成（可以訪問DOM元素）
  mounted () {

  },
  filters: { }, // 過濾器
  beforeCreate () { }, // 生命週期 - 創建之前
  beforeMount () { }, // 生命週期 - 掛載之前
  beforeUpdate () { }, // 生命週期 - 更新之前
  updated () { }, // 生命週期 - 更新之後
  beforeDestroy () { }, // 生命週期 - 銷毀之前
  destroyed () { }, // 生命週期 - 銷毀完成
  activated () { }, // 如果頁面有keep-alive緩存功能，這個函數會觸發
  // 方法集合
  methods: {

  },
  mixins: []
}
</script>

<style lang='scss' scoped>

</style>
